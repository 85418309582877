<!-- 操作手册 -->
<template>
  <div>
    <div class="eg_hwef">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="车辆业务模块" name="1"></el-tab-pane>
        <el-tab-pane label="保养业务模块" name="2"></el-tab-pane>
        <el-tab-pane label="增值业务模块" name="3"></el-tab-pane>
        <el-tab-pane label="个人功能模块" name="4"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="ef_bhygywef">
      <div class="er_hugwf">
        <div class="e_nuyfw">{{ tabName[activeName - 1] }}</div>
        <div class="erg_huhgwf">
          <p
            @click="onTabchange(index)"
            :class="tabIndex == index ? 'name_vsd' : 'name_vsd_active'"
            v-for="(item, index) in carList"
            :key="index"
          >
            {{ index + 1 }}.{{ item }}
          </p>
        </div>
      </div>
      <div class="egr_hygwef">
        <div class="huhwf_hw" ref="obtain">
          <img :src="carImgList[tabIndex]" alt="" />
        </div>
        <div class="ewg_hywef">
          <div @click="onUpper" class="erg_hugwef" v-if="leftName">
            上一篇:{{ leftName }}
          </div>
          <div @click="onLower" class="erg_hugwef" v-if="rightName">
            下一篇:{{ rightName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      activeName: "1",
      tabIndex: 0,
      carList: [],
      carImgList: [],
      leftName: "",
      rightName: "",
      tabName: [
        "·车辆业务模块",
        "·保养业务模块",
        "·增值业务模块",
        "·个人功能模块",
      ],
      maintainName: [
        "保养商品上架流程?",
        "保养商品购买流程?",
        "优惠券制作与发布?",
        "托管业务办理流程?",
      ],
      maintainImg: [
        require("../../../image/ruhesahgnchuanbaoyangchanpin@3x(1)@2x.png"),
        require("../../../image/ruhegoumaibaoyangchanping@3x(1)@2x.png"),
        require("../../../image/ruhezhizuoyouhuijuan@3x(1)@2x.png"),
        require("../../../image/ruhetuoguancheliang@3x(1)@2x.png"),
      ],
      incrementName: ["停驶流程?", "停驶无忧介绍?", "积分商城介绍?"],
      incrementImg: [
        require("../../../image/tingcheruhezhuanqian@3x(1)@2x.png"),
        require("../../../image/tingshiwuyou@3x(1)@2x.png"),
        require("../../../image/jifenshangcheng@3x(1)@2x.png"),
      ],
      personalName: [
        "个人资料编辑?",
        "银行卡管理?",
        "托管收益介绍?",
        "金币介绍?",
      ],
      personalImg: [
        require("../../../image/gerenziliao@3x(1)@2x.png"),
        require("../../../image/yinghangka@3x(1)@2x.png"),
        require("../../../image/tuoguan@3x(1)@2x.png"),
        require("../../../image/jinbi@3x(1)@2x.png"),
      ],
      carname: [
        "商家注册步骤？",
        "车辆上架（卖车）流程?",
        "车辆交易（买车）流程?",
        "车辆租赁流程?",
        "商机客户管理?",
        "商家好友管理?",
        "核销手机管理?",
      ],
      carimg: [
        require("../../../image/shangjiazhuce@3x(1)@2x.png"),
        require("../../../image/ruhesahngjiamaiche@3x(1)@2x.png"),
        require("../../../image/maicheliucheng@2x (1).png"),
        require("../../../image/ruhezuche@3x(1)@2x (1).png"),
        require("../../../image/zenmekandaiwodekehu@3x(1)@2x.png"),
        require("../../../image/zenmechakanweodehaoyou@3x(1)@2x.png"),
        require("../../../image/zenmehexiao@3x(1)@2x.png"),
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClick(e) {
      this.tabIndex = 0;
      this.leftName = "";
      this.rightName = "";
      this.carList = [];
      this.carImgList = [];
      if (e.index == 0) {
        this.carList = this.carname;
        this.carImgList = this.carimg;
        this.rightName = this.carList[1];
      } else if (e.index == 1) {
        this.carList = this.maintainName;
        this.carImgList = this.maintainImg;
        this.rightName = this.carList[1];
      } else if (e.index == 2) {
        this.carList = this.incrementName;
        this.carImgList = this.incrementImg;
        this.rightName = this.carList[1];
      } else if (e.index == 3) {
        this.carList = this.personalName;
        this.carImgList = this.personalImg;
        this.rightName = this.carList[1];
      }
    },
    onTabchange(e) {
      this.tabIndex = e;
      this.carList.map((item, i) => {
        if (i == 0) {
          this.leftName = "";
          this.rightName = this.carList[i + 1];
        }
        if (e == i) {
          this.leftName = this.carList[i - 1];
          this.rightName = this.carList[i + 1];
        }
      });
    },
    onUpper() {
      document.querySelector(".eg_hwef").scrollIntoView(true);
      let upper = this.tabIndex - 1;
      this.onTabchange(upper);
    },
    onLower() {
      document.querySelector(".eg_hwef").scrollIntoView(true);
      let lower = this.tabIndex + 1;
      this.onTabchange(lower);
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    if (this.activeName == 1) {
      this.carList = this.carname;
      this.carImgList = this.carimg;
      this.rightName = this.carList[1];
    }
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.eg_hwef {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  border-bottom: 1px solid #d5d5d5;
}
.eg_hwef /deep/.el-tabs__header {
  margin: 0;
}
.eg_hwef /deep/.el-tabs__item.is-active {
  color: #ff6600;
}
.eg_hwef /deep/.el-tabs__active-bar {
  background-color: #ff6600;
}
.eg_hwef /deep/.el-tabs__item:hover {
  color: #ff6600;
  cursor: pointer;
}
.ef_bhygywef {
  width: 100%;
  padding: 80px 240px 0 240px;
  display: flex;
  justify-content: center;
}

.eg_hgywf {
  display: flex;
  justify-content: center;
}
.eg_hgywf
  /deep/.el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #ff6600;
  color: #fff;
}
.eg_hgywf
  /deep/.el-pagination.is-background
  .el-pager
  li:not(.disabled).active:hover {
  color: #fff;
}
.eg_hgywf /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff6600;
}
.erg_hgwf {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 36px;
  color: #333333;
  padding: 40px 0 30px 0;
  box-sizing: border-box;
}
.erg_hnwd {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #333333;
}
.e_nuyfw {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 61px;
  color: #272727;
}
.erg_huhgwf {
  width: 288px;
  cursor: pointer;
  padding-top: 20px;
}
.name_vsd {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 31px;
  color: #ff6600;
  border-bottom: 1px solid #ececec;
  padding: 20px 0;
}
.name_vsd_active {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 31px;
  color: #272727;
  border-bottom: 1px solid #ececec;
  padding: 20px 0;
}
.egr_hygwef {
  padding-left: 151px;
}
.ewg_hywef {
  width: 720px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 31px;
}
.erg_hugwef {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #333333;
}
.erg_hugwef:hover {
  color: #ff6600;
}
.huhwf_hw {
  width: 720px;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 50px;
}
</style>